<script setup lang="ts">
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { ROUTES } from '~/constants/routes';
import { useBooking } from '~/composables/booking/useBooking';

const { t } = useI18n();
const { getActiveBookings } = useBooking();

useHead({ title: t("routes.main") });
definePageMeta({
    layout: 'private',
    middleware: ['auth']
});

const authStore = useAuthStore();
const { userId } = storeToRefs(authStore);

onMounted(async () => {
    // Проверяем активные бронирования, если больше 2-х, то редиректим на список.
    const result = await getActiveBookings();
    if(!result.isError) {
        const activeBookings = result.data ?? [];
        if(activeBookings.length > 1) {
            navigateTo(ROUTES.bookingClient);
            return;
        }
    }
    
    navigateTo(ROUTES.user(userId.value));
});

</script>

<template>
    <v-skeleton-loader
        color="grey50"
        class="loader"
        type="article, text, button, paragraph, article, paragraph, button"
    />
</template>
